import { setProductAddons, setProductInfo } from '@/redux/actions/modalAction'
import { encode } from 'js-base64'
import { webalizeName } from '@/functions/common/helper'
import { setCurrentTag } from '@/redux/actions/tagAction'

export const hideModal = (router, dispatch) => {
    const branch = router.query.branch
    const category = router.query.category

    let url = '/'
    if (branch !== undefined) url = '/' + branch
    if (category !== undefined) url = url + '/' + category

    router.push(`${url}`)

    dispatch(setProductAddons([]))
    dispatch(
        setProductInfo({
            wareCategoryId: null,
            promoTag: null
        })
    )
}
export const openModalAsNewPage = (router, id, branchSlug) => {
    const branch = router.query.branch
    const category = router.query.category

    let url = ''
    if (branch !== undefined) url = branch
    else url = branchSlug
    if (category !== undefined) url = url + '/' + category

    router.push(`/${url}/detail?id=${encode(id.toString())}`)
}

export const openPromoPage = (router, dispatch, branchSlug) => {
    const category = router.query.category
    const productId = router.query.productId

    let url = branchSlug
    if (category !== undefined) {
        dispatch(setCurrentTag(''))
        url = url + '/' + webalizeName(category)
    }
    if (productId !== undefined) url = url + '/detail?id=' + encode(productId.toString())

    router.push(`/${url}`)
}
