import { useCallback, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { useRouter } from 'next/router'
import { transferOrderHook, userRouterHook } from '@/functions/user/userUtil'
import { useDispatch, useSelector } from 'react-redux'
import { orderIdSelector, userDataSelector, userLoggedInSelector } from '@/selectors/selectors'
import { callGraphQlClient } from '@/functions/loaderGraphQl'
import { logoutUserMutation } from '@/graphql/mutation/user/logoutUserMutation'
import { showSuccess } from '@/functions/ui/toastify'
import { setLogoutUser } from '@/redux/actions/userAction'
import UserAccountIcon from '@/ui/components/menu/icons/UserAccountIcon'
import OverviewIcon from '@/ui/components/menu/icons/OverviewIcon'
import OrdersIcon from '@/ui/components/menu/icons/OrdersIcon'
import CreditIcon from '@/ui/components/menu/icons/CreditIcon'
import SettingIcon from '@/ui/components/menu/icons/SettingIcon'
import TermsOfServiceIcon from '@/ui/components/menu/icons/TermsOfServiceIcon'
import LogoutIcon from '@/ui/components/menu/icons/LogoutIcon'
import Cart from '@/ui/icons/Cart'
import { MConsentsAppLink } from '@/graphql/mutation/user/MConsentsAppLink'
import { useMobileHook } from '../../../hooks/useMobileHook'
import { useLoadAvailableCredits } from '../../../hooks/useLoadAvailableCredits'
import { useRedeemCredit } from '../../../hooks/useRedeemCredit'
import { updateNumber } from '@/redux/actions/orderAction'

const UserAccount = () => {
    const { transferUserOrder } = transferOrderHook()
    const dispatch = useDispatch()
    const userLoggedIn = useSelector(userLoggedInSelector)
    const userData = useSelector(userDataSelector)
    const {
        redirectToLogin,
        redirectToOverview,
        redirectToSettings,
        redirectToCredit,
        redirectToOrders,
        redirectUserBackToOrder
    } = userRouterHook(false)

    const { isMobile } = useMobileHook()

    const orderId = useSelector(orderIdSelector)
    const { wallet } = useLoadAvailableCredits()
    const walletId = wallet?.id ?? null
    const { redeemCredits } = useRedeemCredit()

    const router = useRouter()
    const [menuOpen, setMenuOpen] = useState(false)

    const changeMenuVisibility = useCallback(() => {
        setMenuOpen(prevState => !prevState)
    }, [])

    const renderUser = useCallback(() => {
        let userName = ''
        if (userLoggedIn) {
            if (userData.firstName) userName = userData.firstName
            if (userData.lastName) userName += ' ' + userData.lastName
            if (!userData.firstName && !userName.lastName) userName = 'Uživatel'
        } else userName = 'PŘIHLÁSIT SE'
        return userName
    }, [userData, userLoggedIn])

    const resetCredits = () => {
        if (!walletId) return
        if (!orderId) return

        redeemCredits({
            walletId: parseInt(walletId),
            orderId: parseInt(orderId),
            amount: 0
        })
        dispatch(updateNumber(Math.random()))
    }

    const logoutUser = useCallback(() => {
        callGraphQlClient(logoutUserMutation, null, dispatch).then(res => {
            if (res) {
                resetCredits()
                transferUserOrder(true).then(() => {
                    if (router.query.returnTo) {
                        dispatch(setLogoutUser())
                        router
                            .push(router.query.returnTo)
                            .then(() => {
                                dispatch(updateNumber(Math.random()))
                                showSuccess('Uživatel odhlášen')
                            })
                            .catch(err => console.info(err))
                    } else {
                        dispatch(setLogoutUser())
                        showSuccess('Uživatel odhlášen')
                        dispatch(updateNumber(Math.random()))
                    }
                })
            }
        })
    }, [userLoggedIn, router, resetCredits])

    const menuOptions = [
        { text: 'Můj přehled', onClick: () => redirectToOverview(router), icon: <OverviewIcon />, divider: true },
        { text: 'Moje objednávky', onClick: () => redirectToOrders(router), icon: <OrdersIcon />, divider: false },
        { text: 'Moje kredity', onClick: () => redirectToCredit(router), icon: <CreditIcon />, divider: false },
        { text: 'Nastavení účtu', onClick: () => redirectToSettings(router), icon: <SettingIcon />, divider: false },
        {
            text: 'Správa souhlasů',
            onClick: () => {
                callGraphQlClient(MConsentsAppLink, null, dispatch).then(res => {
                    if (res) router.push(res.consentsAppLink)
                })
            },
            icon: <TermsOfServiceIcon />,
            divider: true
        },
        {
            text: 'Odhlásit se',
            onClick: () => logoutUser(),
            icon: <LogoutIcon fill={isMobile ? 'white' : '#1F2937'} />,
            divider: false
        }
    ]
    if (router && router.pathname.includes('verifikace')) return <></>

    if (userLoggedIn) {
        return (
            <>
                <div className={'inline lg:hidden  border-b  w-full  border-white/[.40]'}></div>
                {router.query.returnTo && (
                    <div
                        onClick={redirectUserBackToOrder}
                        className={'flex flex-row items-center hover:cursor-pointer'}
                    >
                        <Cart fill={isMobile ? '#fff' : '#E30A18'} />
                        <li className={'font-fk_screamer text-2xl pl-3 text-white md:text-turbo-primary'}>
                            Zpět k objednávce
                        </li>
                    </div>
                )}
                <div className={'mx-auto my-3 lg:my-0 lg:ml-16 hover:cursor-pointer relative'}>
                    <ul className={'flex flex-row  items-center'}>
                        <li className={'flex flex-row items-center relative  '} onClick={changeMenuVisibility}>
                            <span className={'hidden lg:inline-flex items-center'}>
                                <span
                                    className={
                                        'font-fk_screamer font-normal text-2xl pr-3 text-white lg:text-turbo-primary'
                                    }
                                >
                                    {renderUser()}
                                </span>
                                <UserAccountIcon height={25} fill={isMobile ? 'white' : '#E30F1B'} />

                                {menuOpen ? <ChevronUp /> : <ChevronDown />}
                            </span>
                            <div className={'lg:hidden text-center'}>
                                <ul>
                                    {menuOptions.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`flex flex-row items-center  text-center pt-4 pb-2 `}
                                                onClick={item.onClick}
                                            >
                                                {index === menuOptions.length - 1 && item.icon}
                                                <li
                                                    className={` mx-auto pl-2 font-fk_screamer font-normal text-2xl xl:text-3xl text-white lg:text-turbo-primary hover:text-black hover:cursor-pointer`}
                                                >
                                                    {item.text}
                                                </li>
                                            </div>
                                        )
                                    })}
                                </ul>
                            </div>
                            {menuOpen && !isMobile && (
                                <div
                                    className={
                                        'w-[203px] right-0 top-10 bg-white  absolute rounded-md text-left flex flex-col drop-shadow-lg'
                                    }
                                >
                                    <ul>
                                        {menuOptions.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={`flex flex-row px-4 pt-4 pb-2 ${
                                                        item.divider && 'border-b border-[rgba(224, 224, 224, 1)]'
                                                    }`}
                                                    onClick={item.onClick}
                                                >
                                                    <div>{item.icon}</div>
                                                    <li className={` text-gray-800 pl-2`}>{item.text}</li>
                                                </div>
                                            )
                                        })}
                                    </ul>
                                </div>
                            )}
                        </li>
                    </ul>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className={'inline lg:hidden  border-b  w-full  border-white/[.40]'}></div>

                <div className={'mx-auto my-3 lg:my-0 lg:ml-16 hover:cursor-pointer relative'}>
                    <ul className={'flex flex-row  items-center'}>
                        <li className={'flex flex-row items-center relative '} onClick={changeMenuVisibility}>
                            <UserAccountIcon fill={isMobile ? 'white' : '#E30F1B'} />
                            <span
                                className={
                                    'font-fk_screamer font-normal text-2xl pl-3 text-white lg:text-turbo-primary hover:text-black hover:cursor-pointer'
                                }
                                onClick={() => redirectToLogin(router)}
                            >
                                {renderUser()}
                            </span>
                        </li>
                    </ul>
                </div>
            </>
        )
    }
}

export default UserAccount
