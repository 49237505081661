import { useCallback, useState } from 'react'
import Image from 'next/image'
import Logo from '@/ui/icons/Logo'
import { useRouter } from 'next/router'
import { setScrollElement } from '@/redux/actions/scrollAction'
import { useDispatch, useSelector } from 'react-redux'
import CartMenu from '@/ui/components/menu/CartMenu'
import { orderDataSelector } from '@/selectors/selectors'
import { setOrderId, setOrderLaterClosedBranch } from '@/redux/actions/orderAction'
import { hideModal } from '@/functions/modal/modalControls'
import { resetOrderAndCreateNew } from '@/functions/orderControl/orderControl'
import { setProductAddons } from '@/redux/actions/modalAction'
import UserAccount from '@/ui/components/menu/UserAccount'

export default function Navbar({ isMobile, city = true, isCartVisible, logoClick = null, showMenu = true }) {
    const router = useRouter()
    let orderData = useSelector(orderDataSelector)
    let dispatch = useDispatch()

    const [showNavbar, setShowNavbar] = useState(false)

    let menuItems = [
        {
            name: 'Pobočky',
            link: '/'
        },
        {
            name: 'Kontakt',
            link: '/kontakt'
        }
    ]

    const openMenu = useCallback(() => {
        setShowNavbar(!showNavbar)
    }, [showNavbar])

    const redirectToID = item => {
        if (item.includes('kontakt')) {
            dispatch(setScrollElement('footer'))
            if (isMobile) openMenu()
        } else {
            router.push(item)
            if (item.includes('/')) {
                dispatch(setOrderLaterClosedBranch(false))
                dispatch(setScrollElement('regions'))
                dispatch(setProductAddons([]))
                if (router.pathname.includes('dekujeme')) resetOrderAndCreateNew(dispatch)
            }
            openMenu()
        }
    }

    const redirectToHP = () => {
        if (logoClick !== null) {
            logoClick()
        } else {
            let url = '/'
            if (router.query.branch !== undefined) url += router.query.branch
            else if (router.pathname.includes('dekujeme')) dispatch(setOrderId(null))

            hideModal(router, dispatch)

            if (router.query.branch !== undefined || router.pathname.includes('dekujeme')) router.push(url)
            else router.reload()
        }
    }
    return (
        <div className={`${city ? 'pb-[86px] lg:pb-[81px]' : 'pb-[70px] md:pb-[67px]'}`}>
            <nav
                className="flex fixed mt-30 justify-between bg-white text-red-600 w-full z-50  shadow-black"
                style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)' }}
            >
                <div className="w-full lg:container mx-auto flex pt-5 pb-4 min-h-20">
                    <div className="pl-4 xs:pl-5 lg:px-5 flex w-full items-center">
                        <div
                            className={'flex flex-col items-start hover:cursor-pointer lg:flex-row lg:items-end'}
                            onClick={() => redirectToHP()}
                        >
                            {city && orderData ? (
                                <>
                                    <Logo className={'logoTurboPizzaBranch'} />
                                    <div
                                        className={
                                            'font-libre_franklin tracking-0-01em mt-1 text-sm text-black leading-tight lg:ml-6'
                                        }
                                    >
                                        {orderData.city}
                                    </div>
                                </>
                            ) : (
                                <Logo />
                            )}
                        </div>
                        <ul className={`mainMenu items-center ` + (showNavbar ? '' : 'closed')}>
                            {showMenu && (
                                <>
                                    {menuItems.map((item, index) => (
                                        <li
                                            key={index}
                                            className={'mx-auto my-3 lg:my-0 lg:ml-16'}
                                            onClick={() => redirectToID(item.link)}
                                        >
                                            <span
                                                className={
                                                    'font-fk_screamer font-normal text-2xl xl:text-3xl text-white lg:text-turbo-primary hover:text-black hover:cursor-pointer'
                                                }
                                            >
                                                {item.name}
                                            </span>
                                        </li>
                                    ))}
                                </>
                            )}

                            <UserAccount />
                            {isCartVisible && !showNavbar && <CartMenu mobile={false} />}
                        </ul>
                    </div>

                    <a
                        className="block self-center mx-4 xs:mx-5 lg:hidden bg-turbo-primary rounded-full p-1"
                        href="#"
                        onClick={openMenu}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 hover:text-gray-200"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="#fff"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </a>
                    <div
                        className={`block cursor-pointer fixed top-5 right-5 z-40 ` + (showNavbar ? '' : 'hidden')}
                        onClick={openMenu}
                    >
                        <Image
                            className={'block absolute top-5 right-5'}
                            src={require('../../../../public/xMenu.svg')}
                            width={25}
                            height={35}
                            alt="#"
                        />
                    </div>
                </div>
            </nav>
        </div>
    )
}
