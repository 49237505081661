import { gql } from 'graphql-request'

export const MRedeemCredits = gql`
    mutation MRedeemCredits($orderId: ID!, $amount: Float!, $walletId: ID!) {
        addCreditsPayment(orderId: $orderId, amount: $amount, walletId: $walletId) {
            order {
                id
            }
        }
    }
`
