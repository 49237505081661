import Image from 'next/image'

import { useAppContext } from '../../../../context/AppContext'
import Link from 'next/link'
import { useRouter } from 'next/router'

const CartMenu = ({ mobile }) => {
    let data = useAppContext()
    let router = useRouter()

    function renderCart() {
        return (
            <>
                {data && data.order.items.length !== 0 ? (
                    <Image alt={'cart_img'} src={'/ic_kosik1.svg'} width={20} height={23} />
                ) : (
                    <Image alt={'cart_img'} src={'/ic_kosik2.svg'} width={20} height={23} />
                )}
            </>
        )
    }

    let branch = router.query.branch

    return (
        <Link href={'/' + branch + '/kosik'}>
            <div>
                {!mobile ? (
                    <div
                        className={`hover:cursor-pointer hover:brightness-95 active:brightness-90 hidden lg:inline-block bg-turbo-background rounded-full px-3 py-2 justify-center flex h-11 w-11 relative pt-3`}
                    >
                        {renderCart()}
                        {data && data.order.items.length !== 0 && (
                            <span
                                className={
                                    'font-libre_franklin_bold absolute -left-1 -top-1  rounded-full bg-turbo-primary text-white text-xs w-5 h-5 flex justify-center items-center'
                                }
                            >
                                {data.order.items.length}
                            </span>
                        )}
                    </div>
                ) : (
                    <div
                        className={`mobile-cart shadow-buttons z-30 fixed  bottom-44 hover:cursor-pointer hover:brightness-95 active:brightness-90  mr-4 visible lg:hidden fixed z-100 right-0   bg-turbo-background rounded-full px-3 py-2 justify-center flex h-16 w-16`}
                    >
                        {renderCart()}
                        {data && data.order.items.length !== 0 && (
                            <span
                                className={
                                    'font-libre_franklin_bold  absolute border border-gray-50 -left-0 -top-1  rounded-full bg-white text-turbo-primary text-xs w-6 h-6 flex justify-center items-center'
                                }
                            >
                                {data.order.items.length}
                            </span>
                        )}
                    </div>
                )}
            </div>
        </Link>
    )
}
export default CartMenu
