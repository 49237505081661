const SvgComponent = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
        <path
            fill={props.fill ? props.fill : '#1F2937'}
            fillRule="evenodd"
            d="M17 9a2 2 0 0 1 1.995 1.85L19 11v6a2 2 0 0 1-1.85 1.995L17 19h-4a2 2 0 0 1-1.995-1.85L11 17v-6a2 2 0 0 1 1.85-1.995L13 9h4ZM7 13a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h4ZM7 1a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h4Zm10 0a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h4Z"
            clipRule="evenodd"
        />
    </svg>
)
export default SvgComponent
