import { gql } from 'graphql-request'

export const userAddresses = gql`
    fragment userAddresses on User {
        addresses {
            id
            addressType {
                id
                rawCode
                enum
                code
            }
            title
            gps {
                latitude
                longitude
            }
            location {
                original
                street
                houseNumber
                postCode
                city
            }
        }
    }
`

export const getUserAddresses = gql`
    query getUserAddresses($id: ID!) {
        user(id: $id) {
            ...userAddresses
        }
    }
    ${userAddresses}
`
