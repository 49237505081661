import { gql } from 'graphql-request'
import { FCreditPrice } from '@/graphql/query/user/customerHistory/FCreditPrice'

export const QAvailableCredits = gql`
    query QAvailableCredits($userId: ID!, $branchId: ID!) {
        customer: user(id: $userId) {
            id

            # we need to get the wallet for the selected branch
            creditsWallets(companyBranchId: $branchId) {
                id
                balance {
                    ...FCreditPrice
                }

                # step of the credits slider
                cashPaymentUnit

                # used for rounding values in the slider
                currency {
                    decimalRound
                }
            }
        }
    }
    ${FCreditPrice}
`
