const SvgComponent = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
        <path
            fill={props.fill ? props.fill : '#1F2937'}
            fillRule="evenodd"
            d="M11.592 1.137C11.26 1 10.84 1 10 1c-.84 0-1.26 0-1.592.137-.44.182-.791.532-.975.974-.084.201-.116.436-.13.777a1.46 1.46 0 0 1-.712 1.218 1.46 1.46 0 0 1-1.413.007c-.302-.16-.521-.248-.738-.277a1.805 1.805 0 0 0-1.333.356c-.284.218-.495.582-.914 1.308-.42.726-.63 1.089-.678 1.444-.062.474.067.953.358 1.332.133.172.32.317.609.5.427.266.7.722.7 1.224s-.273.958-.7 1.224c-.29.183-.476.328-.61.5a1.794 1.794 0 0 0-.357 1.332c.048.354.257.718.678 1.444.42.726.63 1.089.914 1.308.38.29.86.418 1.333.356.217-.029.436-.117.738-.277a1.46 1.46 0 0 1 1.414.007c.435.252.693.716.711 1.218.014.342.046.576.13.777a1.8 1.8 0 0 0 .975.974C8.74 19 9.16 19 10 19c.84 0 1.26 0 1.592-.137.44-.182.791-.532.975-.974.084-.201.116-.435.13-.777.018-.502.276-.967.712-1.218a1.461 1.461 0 0 1 1.413-.007c.302.16.521.248.738.277.474.063.954-.066 1.333-.356.284-.218.495-.582.914-1.308.42-.726.63-1.089.678-1.444a1.803 1.803 0 0 0-.358-1.332c-.133-.172-.32-.317-.609-.5a1.454 1.454 0 0 1-.7-1.224c0-.502.273-.958.7-1.224.29-.183.476-.328.61-.5a1.793 1.793 0 0 0 .357-1.332c-.048-.354-.257-.718-.678-1.444-.42-.726-.63-1.089-.914-1.308-.38-.29-.86-.419-1.333-.356-.217.029-.436.117-.738.277a1.46 1.46 0 0 1-1.414-.007 1.45 1.45 0 0 1-.711-1.218c-.014-.342-.046-.576-.13-.776a1.808 1.808 0 0 0-.975-.975ZM10 12.7a2.702 2.702 0 0 0 2.705-2.7c0-1.491-1.212-2.7-2.705-2.7A2.702 2.702 0 0 0 7.295 10c0 1.491 1.212 2.7 2.705 2.7Z"
            clipRule="evenodd"
        />
    </svg>
)
export default SvgComponent
