const SvgComponent = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none" {...props}>
        <g clipPath="url(#a)">
            <path
                fill={props.fill}
                d="M12 .499c-6.624 0-12 5.376-12 12s5.376 12 12 12 12-5.376 12-12-5.376-12-12-12Zm0 4.8c2.316 0 4.2 1.884 4.2 4.2 0 2.316-1.884 4.2-4.2 4.2a4.205 4.205 0 0 1-4.2-4.2c0-2.316 1.884-4.2 4.2-4.2Zm0 16.8c-2.436 0-5.316-.984-7.368-3.456a11.937 11.937 0 0 1 14.736 0c-2.052 2.472-4.932 3.456-7.368 3.456Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 .5h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
)
export default SvgComponent
