const Cart = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} xmlSpace="preserve" {...props}>
        <path
            fill={props.fill}
            d="M17.237 3.582h-.404l-.002-.004L13.457.205a.696.696 0 0 0-.986 0 .696.696 0 0 0 0 .986l2.39 2.391H5.138l2.391-2.391a.698.698 0 0 0-.987-.986L3.168 3.578l-.002.004h-.403C1.87 3.582 0 3.582 0 6.139c0 .968.195 1.609.623 2.028.242.251.53.381.837.446.289.074.605.084.903.084h15.283c.307 0 .605-.019.884-.084.828-.195 1.47-.789 1.47-2.474 0-2.557-1.87-2.557-2.763-2.557M17.05 10H2.87a.999.999 0 0 0-.99 1.16l.84 5.14C3 18.02 3.75 20 7.08 20h5.61c3.37 0 3.97-1.689 4.33-3.579l1.01-5.23A.997.997 0 0 0 17.05 10m-8.441 6.45a.696.696 0 1 1-1.39 0v-3.3c0-.38.31-.7.7-.7a.7.7 0 0 1 .69.7v3.3zm4.281 0c0 .391-.31.7-.7.7-.38 0-.7-.31-.7-.7v-3.3c0-.38.32-.7.7-.7.39 0 .7.32.7.7v3.3z"
        />
    </svg>
)

export default Cart
