import { useEffect, useMemo, useState } from 'react'

export const useMobileHook = () => {
    const [width, setWidth] = useState(null)
    useEffect(() => {
        if (window) setWidth(window.innerWidth)
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    function handleWindowSizeChange() {
        setWidth(window.innerWidth)
    }

    const isMobile = useMemo(() => {
        return width < 1024
    }, [width])

    return { isMobile }
}
