import { callGraphQlClient } from '@/functions/loaderGraphQl'
import { useDispatch } from 'react-redux'
import { MRedeemCredits } from '@/graphql/mutation/MRedeemCredits'

export const useRedeemCredit = () => {
    const dispatch = useDispatch()

    const redeemCredits = ({ walletId, orderId, amount }) => {
        if (walletId === null) return undefined
        if (orderId === null) return undefined

        const variables = { walletId: walletId, orderId: orderId, amount: amount }
        return callGraphQlClient(MRedeemCredits, variables, dispatch)
    }

    return {
        redeemCredits
    }
}
