import { gql } from 'graphql-request'

export const resolveAddressSuggestions = gql`
    query QDeliveryAddressSuggestions($gps: [InputGeoPointCreate!]!, $brandId: ID!) {
        suggestions: suggestAddress(gps: $gps, brand: $brandId) {
            isResolved
            isValidAddress
            inDeliveryLocation
            gpsCoordinates {
                latitude
                longitude
            }
            zone {
                id
                deliveryTimeDeviation
            }
            finalAddressFormatted
            companyBranch {
                enabled
                id
                name
                address {
                    location {
                        city
                    }
                }
            }
        }
    }
`
