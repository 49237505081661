const Logo = ({ className = 'logoTurboPizzaDefault' }) => (
    <svg className={className} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177 35">
        <path
            d="M83.46 5.408c2.393.006 4.729.72 6.715 2.052a12.08 12.08 0 0 1 1.812 18.593 12.109 12.109 0 0 1-10.931 3.305 12.113 12.113 0 0 1-8.82-7.247 12.08 12.08 0 0 1 1.14-11.35A12.102 12.102 0 0 1 83.46 5.408Zm0-5.408a17.535 17.535 0 0 0-9.733 2.95 17.505 17.505 0 0 0-6.453 7.853 17.481 17.481 0 0 0 3.798 19.071 17.539 17.539 0 0 0 19.094 3.794 17.516 17.516 0 0 0 7.862-6.445A17.485 17.485 0 0 0 95.85 5.125 17.53 17.53 0 0 0 83.46 0ZM14.774.608v5.475h-4.22v28.284H4.22V6.12H0V.61h14.774ZM31.576.608v25.304c0 2.75-.697 4.913-2.09 6.49a7.241 7.241 0 0 1-5.736 2.385 7.249 7.249 0 0 1-5.724-2.36c-1.389-1.577-2.085-3.74-2.089-6.49V.608h6.352v26.947c0 1.435.493 2.147 1.48 2.147.986 0 1.473-.712 1.473-2.147V.608h6.334ZM39.51.608a9.418 9.418 0 0 1 4.812 1.217 8.715 8.715 0 0 1 3.337 3.522 11.546 11.546 0 0 1 1.218 5.505 12.701 12.701 0 0 1-.865 5.036 12.117 12.117 0 0 1-2.722 3.82l3.166 14.21v.419h-6.09l-2.435-12.652h-.61l.263 12.682h-6.322V.608h6.249Zm.086 16.424h.213a2.518 2.518 0 0 0 1.96-.889 3.646 3.646 0 0 0 .78-2.487V9.069a3.646 3.646 0 0 0-.78-2.488 2.514 2.514 0 0 0-1.96-.888h-.225l.012 11.339Z"
            fill="#E30A18"
        />
        <path
            d="M56.1.608a12.022 12.022 0 0 1 4.871.992 8.33 8.33 0 0 1 3.58 2.956 8.125 8.125 0 0 1 1.328 4.72c0 3.739-1.686 6.34-5.06 7.804v.42a8.196 8.196 0 0 1 3.775 2.7c1 1.266 1.499 2.993 1.499 5.183a9.242 9.242 0 0 1-1.218 4.933 7.913 7.913 0 0 1-3.38 3.042 10.972 10.972 0 0 1-4.75 1.01h-6.699V.607h6.053Zm.298 14.337h.42a2.28 2.28 0 0 0 1.827-.84 3.92 3.92 0 0 0 .694-2.53V9.07a3.908 3.908 0 0 0-.694-2.53 2.266 2.266 0 0 0-1.827-.846h-.42v9.252Zm0 14.337h.42a2.5 2.5 0 0 0 1.96-.882c.582-.775.861-1.735.786-2.7v-2.087a3.968 3.968 0 0 0-.785-2.694 2.502 2.502 0 0 0-1.961-.889h-.42v9.252Z"
            fill="#E30A18"
        />
        <path
            d="M83.46 22.16a4.662 4.662 0 0 0 4.666-4.66 4.662 4.662 0 0 0-4.665-4.66 4.662 4.662 0 0 0-4.665 4.66 4.662 4.662 0 0 0 4.665 4.66ZM110.439.493c5.176 0 9.627 3.388 9.627 10.584v.851c0 7.196-4.451 10.584-9.542 10.584v11.855h-6.358V.493h6.273Zm.085 16.94h.426c1.693 0 2.753-.98 2.753-3.388V8.96c0-2.433-1.06-3.382-2.753-3.382h-.426v11.855ZM127.612.493v33.874h-6.358V.493h6.358ZM129.104 29.495l8.264-23.723h-8.264V.492h15.042V5.36l-8.27 23.722h8.27v5.293h-15.042v-4.879ZM145.418 29.495l8.264-23.723h-8.264V.492h15.048V5.36l-8.27 23.722h8.27v5.293h-15.048v-4.879ZM173.815.493 177 33.948v.425h-5.938l-.718-8.047h-2.375l-.719 8.041h-5.937v-.425L164.492.487l9.323.006Zm-3.514 20.322-.932-11.642h-.426l-.932 11.642h2.29Z"
            fill="#215929"
        />
    </svg>
)

export default Logo
