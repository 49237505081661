const SvgComponent = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
        <g clipPath="url(#a)">
            <path
                fill={props.fill}
                fillRule="evenodd"
                d="M-.002 2.144A2.143 2.143 0 0 1 2.141 0h10a2.143 2.143 0 0 1 2.143 2.143v2.77a2.857 2.857 0 0 0-1.049 1.873H8.212a3.214 3.214 0 0 0 0 6.428h5.023a2.857 2.857 0 0 0 1.049 1.873v2.77a2.143 2.143 0 0 1-2.143 2.143h-10a2.143 2.143 0 0 1-2.143-2.143V2.144Zm15.661 4.01a1.071 1.071 0 0 0-.661.99v1.428H8.212a1.429 1.429 0 0 0 0 2.858h6.786v1.428a1.072 1.072 0 0 0 1.829.757l2.857-2.857a1.072 1.072 0 0 0 0-1.514l-2.857-2.857a1.071 1.071 0 0 0-1.168-.233Z"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h20v20H0z" />
            </clipPath>
        </defs>
    </svg>
)
export default SvgComponent
