const SvgComponent = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
        <g clipPath="url(#a)">
            <path
                fill={props.fill ? props.fill : '#1F2937'}
                d="M13.305 10.001a1.515 1.515 0 0 1-1.294-.731L10 5.939l-2.006 3.33a1.52 1.52 0 0 1-1.297.735c-.14 0-.281-.018-.415-.06L2 8.72v5.562c0 .46.313.86.757.97l6.756 1.69c.319.078.653.078.969 0l6.762-1.69c.444-.113.756-.513.756-.97V8.72l-4.28 1.222c-.135.04-.276.06-.416.06Zm6.643-3.506-1.61-3.213a.51.51 0 0 0-.521-.278L10 4.001l2.866 4.753c.119.197.356.29.578.228l6.184-1.765c.31-.09.46-.435.32-.722ZM1.664 3.282.054 6.495a.51.51 0 0 0 .316.719l6.184 1.765a.514.514 0 0 0 .579-.228L10 4.001l-7.818-.997a.51.51 0 0 0-.52.278Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h20v20H0z" />
            </clipPath>
        </defs>
    </svg>
)
export default SvgComponent
